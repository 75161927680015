.school {
  max-width: var(--max-width);
  display: flex;
  justify-content: flex-start;
  width: 100%;
  justify-items: center;
  gap: 1.3em;
  padding-bottom: 50px;
  position: relative;
}

.s-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: var(--offset);
  box-shadow: 5px 5px 10px lightblue;
  background-color: white;
  border-radius: 20px;
}

.year {
  opacity: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.year .s-card {
  width: 100%;
  flex: 1;
}

.s-card-2 {
  margin-bottom: 1.3em;
}

.s-card:hover {
  box-shadow: none;
  animation: slide-down 0.5s ease forwards;
}

.s-card-4 {
  padding: 8%;
}

.s-card ul {
  width: 100%;
}

.school li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.5em;
  cursor: pointer;
}

.school li:hover {
  background-color: rgba(173, 216, 230, 0.2);
  border-radius: 10px;
}

.school ul {
  padding: var(--offset);
  text-align: left;
  display: grid;
  gap: 1em;
}

.school .subject-name {
  flex: 1;
}

.school .subject-grade {
  font-weight: bold;
}

.school h3 {
  margin-bottom: 1em;
}

.animate-school {
  animation: slide-up 0.5s ease-in forwards;
}

#school {
  position: relative;
  z-index: 8;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down {
  from {
    box-shadow: 5px 5px 10px lightblue;
  }
  to {
    box-shadow: 1px 1px 10px lightblue;
  }
  100% {
    box-shadow: 1px 1px 10px lightblue;
  }
}

@media (max-width: 1300px) {
  .s-card-3 {
    padding-right: 5%;
  }

  .s-card-1 {
    padding-left: 5%;
  }
}

@media (max-width: 1000px) {
  .school {
    display: grid;
    padding: 0px;
    justify-content: center;
    align-items: center;
  }

  .s-card {
    grid-column: span 12;
  }

  .school ul {
    width: 100%;
  }

  .s-card-4 {
    max-width: 325px;
    padding: 7%;
  }

  .s-card-3 {
    padding-right: 0%;
  }

  .s-card-1 {
    padding-left: 0%;
  }
}
