@charset "UTF-8";
.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  object-fit: fill;
  z-index: 9;
}

header .header-content {
  flex: 10;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  gap: var(--gutter);
  padding: var(--gutter);
}

.App-header h1 {
  text-shadow: 3px 3px #add8e6;
  margin: 0 auto;
}

.front-photo {
  border-radius: 50%;
  width: 130px;
  height: auto;
  border: solid;
  border-color: lightblue;
  border-width: 5px;
}

.header-content .socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--offset);
}

.socials p {
  font-family: "Roboto-bold";
}

#arrowDown:hover {
  fill: lightblue;
}

.arrowDown {
  flex: 1;
}

.school {
  max-width: var(--max-width);
  display: flex;
  justify-content: flex-start;
  width: 100%;
  justify-items: center;
  gap: 1.3em;
  padding-bottom: 50px;
  position: relative;
}

.s-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: var(--offset);
  box-shadow: 5px 5px 10px lightblue;
  background-color: white;
  border-radius: 20px;
}

.year {
  opacity: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.year .s-card {
  width: 100%;
  flex: 1;
}

.s-card-2 {
  margin-bottom: 1.3em;
}

.s-card:hover {
  box-shadow: none;
  animation: slide-down 0.5s ease forwards;
}

.s-card-4 {
  padding: 8%;
}

.s-card ul {
  width: 100%;
}

.school li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.5em;
  cursor: pointer;
}

.school li:hover {
  background-color: rgba(173, 216, 230, 0.2);
  border-radius: 10px;
}

.school ul {
  padding: var(--offset);
  text-align: left;
  display: grid;
  gap: 1em;
}

.school .subject-name {
  flex: 1;
}

.school .subject-grade {
  font-weight: bold;
}

.school h3 {
  margin-bottom: 1em;
}

.animate-school {
  animation: slide-up 0.5s ease-in forwards;
}

#school {
  position: relative;
  z-index: 8;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-down {
  from {
    box-shadow: 5px 5px 10px lightblue;
  }
  to {
    box-shadow: 1px 1px 10px lightblue;
  }
  100% {
    box-shadow: 1px 1px 10px lightblue;
  }
}
@media (max-width: 1300px) {
  .s-card-3 {
    padding-right: 5%;
  }
  .s-card-1 {
    padding-left: 5%;
  }
}
@media (max-width: 1000px) {
  .school {
    display: grid;
    padding: 0px;
    justify-content: center;
    align-items: center;
  }
  .s-card {
    grid-column: span 12;
  }
  .school ul {
    width: 100%;
  }
  .s-card-4 {
    max-width: 325px;
    padding: 7%;
  }
  .s-card-3 {
    padding-right: 0%;
  }
  .s-card-1 {
    padding-left: 0%;
  }
}
.foodjournal {
  max-width: var(--max-width);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  gap: var(--offset);
  padding: 50px 0px 50px 0px;
  opacity: 0;
}

.foodjournal-content {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gutter);
}

.foodjournal-text {
  text-align: left;
  flex: 4;
  line-height: 1.7;
}

.foodjournal-text h2 {
  margin-bottom: 10px;
  text-shadow: 4px 4px #add8e6;
}

.foodjournal-photos {
  flex: 3;
  text-align: right;
  width: 100%;
}

.foodjournal-photos img {
  width: 450px;
  height: 230px;
  object-fit: cover;
  margin: 7px;
  box-shadow: 15px 15px lightblue;
  border-radius: 20px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .foodjournal-content {
    flex-direction: column;
  }
  .foodjournal-photos {
    text-align: center;
  }
  .foodjournal-photos img {
    width: 300px;
    height: 153.3333333333px;
  }
  .foodjournal-text {
    text-align: center;
    padding-bottom: var(--offset);
  }
}
.skills {
  padding-top: 5em;
  padding-bottom: 6em;
  display: flex;
  flex-wrap: wrap;
  max-width: var(--max-width);
  gap: 60px;
  justify-content: center;
}

.background-bar {
  width: 330px;
  height: 20px;
  background-color: rgb(225, 225, 225);
  border-radius: 20px;
}

.front-bar {
  width: 0;
  height: 20px;
  background-color: lightblue;
  border-radius: 20px;
}

.animate-bar {
  animation: fillBar 1.5s ease-in-out forwards;
}

#arrowUp {
  transform: rotate(180deg);
}

#arrowUp:hover {
  fill: lightblue;
}

@keyframes fillBar {
  from {
    width: 0;
  }
}
.footer {
  width: 100%;
  height: 50px;
  background-color: rgb(197, 197, 197);
}

.jobs {
  max-width: var(--max-width);
}

.job-card {
  padding-bottom: var(--wrapper);
  box-shadow: 3px 3px 10px lightblue;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
  margin: 20px;
  text-align: left;
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
}

.job-card::before {
  background: linear-gradient(130deg, transparent 0% 33%, rgba(255, 255, 255, 0.4) 66%, rgba(173, 216, 230, 0.4) 100%);
  background-position: 0% 0%;
  background-size: 300% 300%;
  border-radius: 20px;
  content: "";
  transition: background-position 350ms;
  height: calc(100% + 100px);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.job-card:hover::before {
  background-position: 100% 100%;
}

.job-card:hover {
  box-shadow: 1px 1px 10px lightblue;
}

.job-card h3,
.job-card h4,
.job-card p {
  z-index: 1;
  position: relative;
}

* {
  margin: 0;
  padding: 0;
}

/* Reiknum stærð á öllum boxum sem border-box */
html {
  box-sizing: border-box;
  /* Lætur skroll innan síðu taka tíma */
  scroll-behavior: smooth;
}

/* og látum erfast á allt */
*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  /* Grunn bilið okkar er 1rem == 16px */
  --spacing: 1rem;
  /* Hámarksbreidd á efni */
  --max-width: 1200px;
  --color-primary: #f0f0f8;
  /* Letur stillingar */
  --font-base-body-px: 16px;
  --font-base-size: 1rem;
  --font-family-base: "Nunito", Helvetica, Arial, sans-serif;
  --font-family-heading: "Domine", Georgia, serif;
  /* Grid stillingar */
  --columns: 12;
  --gutter: 30px;
  --offset: 20px;
  --wrapper: 50px;
}

h1,
h2,
h3,
h4 {
  font-family: "Roboto-bold";
  font-weight: bold;
}

p {
  font-family: "Roboto";
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto-bold";
  src: url("./fonts/Roboto-Bold.ttf");
}
body {
  color: var(--color-text);
  font-size: var(--font-base-body-px);
  line-height: 1.5;
  font-family: var(--font-family-base);
}

.wrapper {
  background-color: #fbfafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--wrapper);
  min-height: 100vh;
  gap: 2em;
}

.wrapper-2 {
  padding: var(--wrapper);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--wrapper);
}

.wrapper h2 {
  text-shadow: 4px 4px #add8e6;
  padding-bottom: var(--gutter);
}

.wrapper-2 h2 {
  text-shadow: 4px 4px #add8e6;
  padding-bottom: var(--gutter);
}

