.foodjournal {
  max-width: var(--max-width);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  gap: var(--offset);
  padding: 50px 0px 50px 0px;
  opacity: 0;
}

.foodjournal-content {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gutter);
}

.foodjournal-text {
  text-align: left;
  flex: 4;
  line-height: 1.7;
}

.foodjournal-text h2 {
  margin-bottom: 10px;
  text-shadow: 4px 4px #add8e6;
}

.foodjournal-photos {
  flex: 3;
  text-align: right;
  width: 100%;
}

.foodjournal-photos img {
  width: 450px;
  height: 230px;
  object-fit: cover;
  margin: 7px;
  box-shadow: 15px 15px lightblue;
  border-radius: 20px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .foodjournal-content {
    flex-direction: column;
  }

  .foodjournal-photos {
    text-align: center;
  }

  .foodjournal-photos img {
    width: calc(450px / 1.5);
    height: calc(230px / 1.5);
  }

  .foodjournal-text {
    text-align: center;
    padding-bottom: var(--offset);
  }
}
