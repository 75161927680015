@import "./styles/header.scss";
@import "./styles/school.scss";
@import "./styles/foodjournal.scss";
@import "./styles/skills.scss";
@import "./styles/footer.scss";
@import "./styles/experience.scss";

* {
  margin: 0;
  padding: 0;
}

/* Reiknum stærð á öllum boxum sem border-box */
html {
  box-sizing: border-box;

  /* Lætur skroll innan síðu taka tíma */
  scroll-behavior: smooth;
}

/* og látum erfast á allt */
*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  /* Grunn bilið okkar er 1rem == 16px */
  --spacing: 1rem;
  /* Hámarksbreidd á efni */
  --max-width: 1200px;

  --color-primary: #f0f0f8;

  /* Letur stillingar */
  --font-base-body-px: 16px;
  --font-base-size: 1rem;
  --font-family-base: "Nunito", Helvetica, Arial, sans-serif;
  --font-family-heading: "Domine", Georgia, serif;

  /* Grid stillingar */
  --columns: 12;
  --gutter: 30px;
  --offset: 20px;
  --wrapper: 50px;
}

h1,
h2,
h3,
h4 {
  font-family: "Roboto-bold";
  font-weight: bold;
}

p {
  font-family: "Roboto";
}

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-bold";
  src: url("./fonts/Roboto-Bold.ttf");
}

body {
  color: var(--color-text);
  font-size: var(--font-base-body-px);
  line-height: 1.5;
  font-family: var(--font-family-base);
}

.wrapper {
  background-color: #fbfafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--wrapper);
  min-height: 100vh;
  gap: 2em;
}

.wrapper-2 {
  padding: var(--wrapper);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--wrapper);
}

.wrapper h2 {
  text-shadow: 4px 4px #add8e6;
  padding-bottom: var(--gutter);
}

.wrapper-2 h2 {
  text-shadow: 4px 4px #add8e6;
  padding-bottom: var(--gutter);
}
