.jobs {
  max-width: var(--max-width);
}

.job-card {
  padding-bottom: var(--wrapper);
  box-shadow: 3px 3px 10px lightblue;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
  margin: 20px;
  text-align: left;
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
}

.job-card::before {
  background: linear-gradient(
    130deg,
    transparent 0% 33%,
    rgb(255, 255, 255, 0.4) 66%,
    rgb(173, 216, 230, 0.4) 100%
  );
  background-position: 0% 0%;
  background-size: 300% 300%;
  border-radius: 20px;
  content: "";
  transition: background-position 350ms;
  height: calc(100% + 100px);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.job-card:hover::before {
  background-position: 100% 100%;
}

.job-card:hover {
  box-shadow: 1px 1px 10px lightblue;
}

.job-card h3,
.job-card h4,
.job-card p {
  z-index: 1;
  position: relative;
}
