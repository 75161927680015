.App {
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  object-fit: fill;
  z-index: 9;
}

header .header-content {
  flex: 10;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  gap: var(--gutter);
  padding: var(--gutter);
}

.App-header h1 {
  text-shadow: 3px 3px #add8e6;
  margin: 0 auto;
}

.front-photo {
  border-radius: 50%;
  width: 130px;
  height: auto;
  border: solid;
  border-color: lightblue;
  border-width: 5px;
}

.header-content .socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--offset);
}

.socials p {
  font-family: "Roboto-bold";
}

#arrowDown:hover {
  fill: lightblue;
}

.arrowDown {
  flex: 1;
}
