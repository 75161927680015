.skills {
  padding-top: 5em;
  padding-bottom: 6em;
  display: flex;
  flex-wrap: wrap;
  max-width: var(--max-width);
  gap: 60px;
  justify-content: center;
}

.background-bar {
  width: 330px;
  height: 20px;
  background-color: rgb(225, 225, 225);
  border-radius: 20px;
}

.front-bar {
  width: 0;
  height: 20px;
  background-color: lightblue;
  border-radius: 20px;
}

.animate-bar {
  animation: fillBar 1.5s ease-in-out forwards;
}

#arrowUp {
  transform: rotate(180deg);
}

#arrowUp:hover {
  fill: lightblue;
}

@keyframes fillBar {
  from {
    width: 0;
  }
}
